import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LogOut, Home, LogIn, User, LayoutDashboard, ClipboardCheck, Globe, Zap, MapPin, Tag, Search } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

const Drawer = ({ 
  isOpen, 
  onClose, 
  signOut, 
  userInfo, 
  isLoading, 
  onRecommendedClick, 
  setShowLogin,
  boards,
  boardsLoading,
  onUserItemClick,
  followItems,
  updateModalState,
  setFollowItems,
  handleNavigation
}) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      updateModalState(true);
    }
    return () => {
      if (isOpen) {
        updateModalState(false);
      }
    };
  }, [isOpen, updateModalState]);

  const handleHomeClick = () => {
    handleNavigation(`${location.pathname}?ch=discover`);
    onClose();
  };

  const handleSampleRecommendClick = (recommendId) => {
    handleNavigation(`${location.pathname}?ch=recommend-${recommendId}`);
    onClose();
  };

  const handleLogin = () => {
    // 現在のURLパラメータを取得してlocalStorageに保存
    const currentParams = new URLSearchParams(window.location.search).toString();
    localStorage.setItem('loginRedirectParams', currentParams);
  
    setShowLogin(true);
    onClose();
  };

  const moveItemToTop = useCallback((type, id, url_param) => {
    setFollowItems(prevItems => {
      // 移動するアイテムを見つける
      const itemToMove = prevItems.find(item => 
        item.type === type && item.id === id && item.url_param === url_param
      );

      if (!itemToMove) return prevItems;

      // 残りのアイテムをフィルタリング
      const otherItems = prevItems.filter(item => 
        !(item.type === type && item.id === id && item.url_param === url_param)
      );

      // 移動するアイテムを先頭に追加
      return [itemToMove, ...otherItems];
    });
  }, [setFollowItems]);

  const handleItemClick = useCallback((type, id, url_param) => {
    if (type === 'board' && id) {
      handleNavigation(`${location.pathname}?tbl=${id}`);
    } else if (url_param) {
      handleNavigation(`${location.pathname}?${url_param}`);
    }
    moveItemToTop(type, id, url_param);
    onClose();
  }, [handleNavigation, location.pathname, moveItemToTop, onClose]);


  const toggleLanguage = () => {
    const currentLang = i18n.language;
    const newLang = currentLang === 'en' ? 'jp' : 'en';

    const currentPath = location.pathname;
    const newPath = currentLang === 'en' 
      ? currentPath.replace('/en', '') 
      : `/en${currentPath}`;

    const newUrl = `${window.location.origin}${newPath}${location.search}`;

    i18n.changeLanguage(newLang, () => {
      window.location.href = newUrl;
    });

    onClose();
  };

  const renderBoardList = (boardList, title) => (
    <>
      <div className="nav-section">{title}</div>
      {boardList.length === 0 ? (
        <li className="no-boards">{t('noBoards')}</li>
      ) : (
        boardList.map(board => (
          <li 
            key={board.id} 
            className="board-item"
            onClick={() => handleItemClick('board', board.id)}
          >
            {board.role === "member" && board.owner_avatar_url ? (
              <img 
                src={board.owner_avatar_url} 
                alt="Board Avatar" 
                style={{ width: '16px', height: '16px', borderRadius: '10%', marginRight: '15px' }}
              />
            ) : (
              <LayoutDashboard size={16} />
            )}
            <div className="board-item-content">
              <span>{board.name}</span>
              {board.role === "member" &&
              <small className="user-name">
                {board.owner_name && board.owner_name.trim() !== '' ? board.owner_name : t('unsetName')}
              </small>
              }
            </div>
          </li>
        ))
      )}
    </>
  );

  const renderFollowList = () => (
    <>
      <div className="nav-section">{t('followList')}
      {followItems.length !== 0 &&
        <span className='follow-count'>{followItems.length}/20</span>
      }
      </div>
      {followItems.length === 0 ? (
        <li className='no-follow-list'>{t('noFollows')}</li>
      ) : (
        followItems.map(item => (
          <li 
            key={`${item.type}-${item.id}-${item.url_param}`} 
            className={`follow-items board-item`}
            onClick={() => handleItemClick(item.type, item.id, item.url_param)}
          >
            {item.avatar_url ? (
              <img 
                src={item.avatar_url} 
                alt="Avatar" 
                style={{ width: '16px', height: '16px', borderRadius: '10%', marginRight: '15px' }}
              />
            ) : item.type === 'board' ? (
              <LayoutDashboard size={16} />
            ) : item.type === 'loc' ? (
              <MapPin size={16} />
            ) : (
              <Tag size={16} />
            )}
            <div className="follow-item-content">
              <span>{item.display_title}</span>
              {item.type === 'board' && (
                <small className="user-name">
                  {item.display_description && item.display_description.trim() !== '' ? item.display_description : t('unsetName')}
                </small>
              )}
            </div>
          </li>
        ))
      )}
    </>
  );

  const renderMainContent = () => {
    const ownerBoards = boards.filter(board => board.role === "owner");
    const memberBoards = boards.filter(board => board.role === "member");

    return (
      <>
        <nav className="drawer-nav">
          <ul>
             {userInfo && (
              <li className="nav-item recommended-item" onClick={() => {
                onRecommendedClick();
                onClose();
              }}>
                <Zap size={20} />
                <span>{t('recommended')}</span>
              </li>
            )}            
            <li className="nav-item home-item" onClick={handleHomeClick}>
              <Search size={20} />
              <span>{t('top')}</span>
            </li>   
            {(!userInfo && i18n.language === 'jp')&& (
              <>
                <li className="nav-item recommended-item" onClick={() => handleSampleRecommendClick('ex01')}>
                  <Zap size={20} />
                  <div className="board-item-content">
                    <span className='recommend-sample'>{t('recommendedSample1')}</span>
                    <small className="user-name">{t('sampleName1')}</small>
                  </div>
                </li>
                <li className="nav-item recommended-item" onClick={() => handleSampleRecommendClick('ex02')}>
                  <Zap size={20} />
                  <div className="board-item-content">
                    <span className='recommend-sample'>{t('recommendedSample2')}</span>
                    <small className="user-name">{t('sampleName2')}</small>
                  </div>
                </li>
                <li className="nav-item recommended-item" onClick={() => handleSampleRecommendClick('ex03')}>
                  <Zap size={20} />
                  <div className="board-item-content">
                    <span className='recommend-sample'>{t('recommendedSample3')}</span>
                    <small className="user-name">{t('sampleName3')}</small>
                  </div>
                </li>
              </>
            )}                        
            {userInfo && (
              <li className="nav-item user-item" onClick={onUserItemClick}>
                <User size={20} />
                <span>{userInfo.username}</span>
              </li>
            )}                
            {userInfo && (
              <>
                {boardsLoading ? (
                  <li className='loading-boards'>{t('loadingBoards')}</li>
                ) : (
                  <>
                    {renderBoardList(ownerBoards, t('myTables'))}
                    {renderBoardList(memberBoards, t('memberTables'))}
                    {renderFollowList()}
                  </>
                )}
              </>
            )}
          </ul>
        </nav>
        <div className="drawer-footer">
          <button className="language-toggle" onClick={toggleLanguage}>
            <Globe size={14} />
            <span>{i18n.language === 'en' ? '日本語' : 'English'}</span>
          </button>
          {userInfo ? (
            <button className="logout-button" onClick={signOut}>
              <LogOut size={20} />
              <span>{t('logOut')}</span>
            </button>
          ) : (
            <button className="login-button" onClick={handleLogin}>
              <LogIn size={20} />
              <span>{t('logIn')}</span>
            </button>
          )}
          <a 
            href="https://hello.poppin.link/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="corporate-link"
          >
            poppin.link
          </a>
        </div>
      </>
    );
  };

  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        {isLoading ? (
          <div className="loading">{t('loading')}</div>
        ) : (
          renderMainContent()
        )}
      </div>
    </div>
  );
};

export default Drawer;