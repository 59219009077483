import ReactGA from "react-ga4";
import React, { useState, useEffect, useCallback, useRef, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Edit, UserPlus, ListFilter, Rss } from 'lucide-react';
import Masonry from 'react-masonry-css';
import './App.css';
import Header from './components/Header';
import Drawer from './components/Drawer';
import Card from './components/Card';
import FullScreenCard from './components/FullScreenCard';
import useInfiniteScroll from './hooks/useInfiniteScroll';
import useBodyScrollLock from './hooks/useBodyScrollLock';
import { fetchItems, submitNewUrl, fetchBoards, addItemToBoard, createBoard, updateUserData, updateBoard, deleteBoard, fetchFollowItems, followItem, unfollowItem, fetchTags, processInvite, fetchClipData } from './services/api';
import { getComments, addComment } from './services/commentService';
import { handleNewItems } from './utils/helpers';
import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { awsConfig } from './aws-exports';
import { useAuth } from './hooks/useAuth';
import UserDataUpdateModal from './components/UserDataUpdateModal';
import BoardEditModal from './components/BoardEditModal';
import ShareButton from './components/ShareButton';
import FollowButton from './components/FollowButton';
import SearchButton from './components/SearchButton';
import ConfirmationModal from './components/ConfirmationModal';
import { amplifyTheme } from './amplify/amplifyTheme';
import { amplifyFormFields } from './amplify/amplifyFormFields';
import { amplifyComponents } from './amplify/amplifyComponents';
import { getCurrentLanguage } from './utils/languageUtils';
import BackgroundImage from './components/BackgroundImage';
import { useTranslation } from 'react-i18next';
import './i18n';
import { useSwipeable } from 'react-swipeable';
import InviteModal from './components/InviteModal';
import InviteResultModal from './components/InviteResultModal';
import LeaveBoardModal from './components/LeaveBoardModal';
import Toast from './components/Toast';

import NewsCard from './components/NewsCard';
import FullScreenNewsCard from './components/FullScreenNewsCard';

import FollowTip from './components/FollowTip';
import TopicCard from './components/TopicCard';
import RecommendedTags from './components/RecommendedTags';
import { useFollowState } from './hooks/useFollowState';
import { useBoard } from './hooks/useBoard';

Amplify.configure(awsConfig);

const breakpointColumnsObj = {
  default: 5,
  1200: 4,
  800: 3,
  600: 2,
  370: 1
};

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();

  // GA4の初期化
  useEffect(() => {
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    }
  }, []);

  // ページビューのトラッキング
  useEffect(() => {
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [location]);

  React.useEffect(() => {
    const currentLang = location.pathname.startsWith('/en') ? 'en' : 'jp';
    i18n.changeLanguage(currentLang);
  }, [location.pathname, i18n]);

  return <MainContent />;
}

function MainContent() {
  const { t, i18n } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [activeTab, setActiveTab] = useState('term');
  const [newCardId, setNewCardId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [overlayImage, setOverlayImage] = useState(null);
  const [commentsFetched, setCommentsFetched] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [urlParams, setUrlParams] = useState({});
  const [shouldFetch, setShouldFetch] = useState(false);
  const loginDialogRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isUserDataModalOpen, setIsUserDataModalOpen] = useState(false);
  const { userInfo, isLoading: authLoading, signOut, refetchUserInfo } = useAuth();
  const [isBoardEditModalOpen, setIsBoardEditModalOpen] = useState(false);
  const [urlInput, setUrlInput] = useState('');
  const [isFollowListVisible, setIsFollowListVisible] = useState(false);
  const [isAnyModalOpen, setIsAnyModalOpen] = useState(false);
  useBodyScrollLock(isAnyModalOpen);
  const [pastItems, setPastItems] = useState([]);
  const [isFullScreenCardOpen, setIsFullScreenCardOpen] = useState(false);
  const [cachedTags, setCachedTags] = useState([]);
  const [cachedSensitiveTags, setCachedSensitiveTags] = useState([]); // 新しく追加
  const [isTagsFetched, setIsTagsFetched] = useState(false);
  const [isTagsLoading, setIsTagsLoading] = useState(false);
  const [isSensitive, setIsSensitive] = useState(false);
  const [showAgeConfirmation, setShowAgeConfirmation] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [tagsValidCombinations, setTagsValidCombinations] = useState([]); // 追加
  const [sensitiveTagsValidCombinations, setSensitiveTagsValidCombinations] = useState([]); // 追加
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteResult, setInviteResult] = useState(null);
  const [isInviteResultModalOpen, setIsInviteResultModalOpen] = useState(false);
  const [isPending, startTransition] = useTransition();
  const [isLeaveBoardModalOpen, setIsLeaveBoardModalOpen] = useState(false);
  const [clipData, setClipData] = useState({});
  const [isClipDataFetched, setIsClipDataFetched] = useState(false);
  const [showFollowListOnNextOpen, setShowFollowListOnNextOpen] = useState(false);
  const [eventListMode, setEventListMode] = useState('upcoming');
  const [toast, setToast] = useState({ message: '', visible: false });

  const [showNewsCard, setShowNewsCard] = useState(false);
  const [newsData, setNewsData] = useState({ news_items: [], header_title: '' });
  const [showFollowTip, setShowFollowTip] = useState(false);

  const [selectedNewsIndex, setSelectedNewsIndex] = useState(0);

  const currentRequestRef = useRef(null);

  const [isContentLoading, setIsContentLoading] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [locationKey, setLocationKey] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [fetchError, setFetchError] = useState(null);

  const [isRetryButtonDisabled, setIsRetryButtonDisabled] = useState(true);
  const [retryTimerStart, setRetryTimerStart] = useState(0);

  const isTopPage = !urlParams.tbl && !urlParams.tag && !urlParams.loc && !urlParams.area && !urlParams.style && !urlParams.ch;
  const isDiscoverPage = !userInfo
  ? (!urlParams.tbl && !urlParams.tag && !urlParams.loc && !urlParams.area && !urlParams.style && !urlParams.ch)
  : (urlParams.ch === "discover");

  const isRecommendPage = urlParams.ch === "recommend" || (isTopPage && userInfo);

  // itemsが更新されたときにページトップにスクロールするuseEffectを追加
  useEffect(() => {
    if (page === 1) {
      window.scrollTo(0, 0);
    }
  }, [items, page]);

  // showLoginの変更を監視し、GA4イベントを送信するuseEffect
  useEffect(() => {
    if (showLogin) {
      ReactGA.event({
        action: "open_login_modal",  // これが event_name になります
        category: "User Interaction",
        label: boardTitle || "Home Page"
      });
    }
  }, [showLogin]);

  const updateModalState = useCallback((isOpen) => {
    setIsAnyModalOpen(isOpen);
  }, []);

  useEffect(() => {
    const currentLang = location.pathname.startsWith('/en') ? 'en' : 'jp';
    i18n.changeLanguage(currentLang);
  }, [location.pathname, i18n]);



  const showInfoBar = useCallback((message) => {
    setToast({ message, visible: true });
  }, []);


  const {
    boards,
    boardsLoading,
    boardTitle,
    boardDescription,
    boardSubtitle,
    boardMemberCount,
    avatarUrl,
    isBoardOwner,
    isBoardMember,
    loadBoards,
    handleCreateBoard,
    // handleBoardUpdate,
    // handleBoardDelete,
    setBoardInfo,
    clearBoardInfo
  } = useBoard(showInfoBar, navigate);

  const handleBoardUpdate = useCallback(async (newBoardData) => {
    try {
      await updateBoard(urlParams.tbl, newBoardData);
      showInfoBar(t('tableDataUpdated'));
      setIsBoardEditModalOpen(false);
      updateModalState(false);
      await loadBoards();
  
      // ボード情報更新後にカードデータを再取得
      setPage(1);
      setItems([]);
      setPastItems([]);
      setHasMore(true);
      setShouldFetch(true);
  
    } catch (error) {
      console.error(t('tableDataUpdateError'), error);
      showInfoBar(t('tableDataUpdateFailed'));
    }
  }, [urlParams.tbl, showInfoBar, loadBoards, updateModalState, t, setPage, setItems, setPastItems, setHasMore, setShouldFetch]);

  const handleBoardDelete = useCallback(async () => {
    try {
      await deleteBoard(urlParams.tbl);
      showInfoBar(t('tableDeleted'));
      setIsBoardEditModalOpen(false);
      updateModalState(false);
      clearBoardInfo();
      loadBoards();
      navigate('/');
    } catch (error) {
      console.error(t('tableDeleteError'), error);
      showInfoBar(t('tableDeleteFailed'));
    }
  }, [urlParams.tbl, showInfoBar, clearBoardInfo, loadBoards, navigate, updateModalState, t]);


  const {
    followItems,
    isFollowStateLoaded,
    isFollowLoading,
    isItemFollow,
    handleFollowAction,
    updateFollowItems  // 新しく追加
  } = useFollowState(userInfo, showInfoBar, setShowLogin, updateModalState);

  const fetchItemsWithParams = useCallback(async (params, isLoadingMore = false) => {

    if (currentRequestRef.current) {
      currentRequestRef.current.abort();
    }

    const abortController = new AbortController();
    currentRequestRef.current = abortController;

    try {
      if (!isLoadingMore) {
        setIsContentLoading(true);
        setIsLoading(true);
        clearBoardInfo();  // データフェッチの前にボード情報をクリア
        setPage(1);  // ここでページを1にリセット
        params = { ...params, page: 1 };  // paramsのページも1にリセット
      }
      setIsLoadingMore(isLoadingMore);
      
      const lang = i18n.language === 'en' ? 'lng-en' : 'lng-jp';
      
      const { tag, loc, area, style, exclude_tag, exclude_loc, exclude_area, exclude_style, ...otherParams } = params;
      
      const apiParams = {
        ...otherParams,
        lng: lang
      };
      
      const addArrayParam = (key, value) => {
        if (value) {
          apiParams[key] = value.split(',');
        }
      };
  
      addArrayParam('tag', tag);
      addArrayParam('loc', loc);
      addArrayParam('area', area);
      addArrayParam('style', style);
      addArrayParam('exclude_tag', exclude_tag);
      addArrayParam('exclude_loc', exclude_loc);
      addArrayParam('exclude_area', exclude_area);
      addArrayParam('exclude_style', exclude_style);
      
      const data = await fetchItems(apiParams, abortController.signal);

      // Replace individual setter calls with setBoardInfo
      setBoardInfo(data);

    // 背景画像URLを設定
    setBackgroundImageUrl(data.backgroundImageUrl || null);
    
    const newItems = params.page === 1 ? data.items : data.items.filter(newItem => !items.some(item => item.id === newItem.id));
    const newPastItems = params.page === 1 ? data.past_items : data.past_items.filter(newItem => !pastItems.some(item => item.id === newItem.id));
    
    // setItems(prevItems => params.page === 1 ? newItems : [...prevItems, ...newItems]);
    // setPastItems(prevPastItems => params.page === 1 ? newPastItems : [...prevPastItems, ...newPastItems]);

    if (params.page > 1 && items.length === 0) {
      setHasMore(false);
      setFetchError(t('fetchItemsErrorMessage'));
      setIsRetryButtonDisabled(true);
      setRetryTimerStart(Date.now());
    } else {
      setItems(prevItems => params.page === 1 ? newItems : [...prevItems, ...newItems]);
      setPastItems(prevPastItems => params.page === 1 ? newPastItems : [...prevPastItems, ...newPastItems]);
      setHasMore(newItems.length > 0 || newPastItems.length > 0);
      setFetchError(null);
    }
    

    if (data.news_data) {
      setNewsData(data.news_data);
    } else {
      setNewsData({ news_items: [], header_title: '' });
    }

    setHasMore(newItems.length > 0 || newPastItems.length > 0);
       
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error(t('fetchItemsError'), error);
        setHasMore(false);
        clearBoardInfo();
        setFetchError(t('fetchItemsErrorMessage')); // エラーメッセージをセット
      }
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
      setShouldFetch(false);
      setIsContentLoading(false);
      currentRequestRef.current = null;
    }
  }, [items, pastItems, t, i18n.language, clearBoardInfo, setBoardInfo]);
  
  useEffect(() => {
    startTransition(() => {
      setIsContentLoading(true);
      setLocationKey(prev => prev + 1);
      const searchParams = new URLSearchParams(location.search);
      const newParams = Object.fromEntries(searchParams.entries());
      const isSensitiveParam = searchParams.get('is_sensitive');
      setIsSensitive(isSensitiveParam === 'true');
      setUrlParams(newParams);
      setPage(1);
      setItems([]);
      setPastItems([]);
      setHasMore(true);
      setShouldFetch(true);
      setEventListMode('upcoming');
      setNewsData({ news_items: [], header_title: '' });
      clearBoardInfo();
    });
  }, [location, clearBoardInfo, forceUpdate, startTransition]);

  // 新しい useEffect for タイトル更新
  useEffect(() => {
    let title = t('AppName');
    let description = t('defaultDescription'); // "気になるイベントを探す、集める、伝える。" の翻訳キー

    if (urlParams.tbl) {
      title = `${boardTitle || t('board')} - ${title}`;
      description = boardSubtitle || description;
    } else if (urlParams.tag) {
      title = `${urlParams.tag} - ${title}`;
      description = t('tagDescription', { tag: urlParams.tag }); // "「{tag}」に関連するイベント情報" のような翻訳キー
    }

    document.title = title;
    
    // メタディスクリプションの更新
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }
  }, [urlParams, boardTitle, boardSubtitle, t]);

  useEffect(() => {
    if (isNavigating) {
      setIsContentLoading(true);
      setIsNavigating(false);
    }
  }, [location, isNavigating]);

  useEffect(() => {
    if (shouldFetch) {
      const params = { ...urlParams, page };
      fetchItemsWithParams(params);
    }
  }, [urlParams, page, fetchItemsWithParams, shouldFetch]);

  const handleNavigation = useCallback((path) => {
    setIsContentLoading(true);
    if (path === location.pathname + location.search) {
      // 同じパスの場合、強制的に再フェッチする
      startTransition(() => {
        setForceUpdate(prev => prev + 1);
        setLocationKey(prev => prev + 1);
      });
    } else {
      navigate(path);
    }
  }, [navigate, location, startTransition]);


  const loadMoreItems = useCallback(() => {
    if (!isLoading && !isLoadingMore && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      const params = { ...urlParams, page: nextPage };
      fetchItemsWithParams(params, true);
    }
  }, [isLoading, isLoadingMore, hasMore, page, urlParams, fetchItemsWithParams]);

  useInfiniteScroll(loadMoreItems);

  useEffect(() => {
    if (userInfo) {
      loadBoards();
    }
  }, [userInfo, loadBoards]);

  useEffect(() => {
    if (activeTab === 'comments' && selectedItem && !commentsFetched) {
      getComments(selectedItem.id, setCommentsLoading, setSelectedItem, setCommentsFetched);
    }
  }, [activeTab, selectedItem, commentsFetched]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (loginDialogRef.current && !loginDialogRef.current.contains(event.target)) {
        setShowLogin(false);
        updateModalState(false);
      }
    }

    if (showLogin) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showLogin, updateModalState]);

  const hideToast = useCallback(() => {
    setToast(prev => ({ ...prev, visible: false }));
  }, []);

  // handleClick 関数を次のように更新します
  const handleClick = useCallback((item) => {
    setSelectedItem(item);
    setActiveTab('term');
    setCommentsFetched(false);
    updateModalState(true);
    setIsFullScreenCardOpen(true);  // 追加
  }, [updateModalState]);

  // handleClose 関数を次のように更新します
  const handleClose = useCallback(() => {
    setSelectedItem(null);
    setShowAddForm(false);
    updateModalState(false);
    setIsFullScreenCardOpen(false);  // 追加
  }, [updateModalState]);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (!userInfo) {
      console.error(t('userNotAuthenticated'));
      return;
    }
    const newComment = { user: userInfo.username, text: e.target.comment.value, time: t('justNow') };
    addComment(selectedItem, setSelectedItem, newComment);
    e.target.comment.value = '';
  };


  const handleImageClick = (image) => {
    setOverlayImage(image);
    updateModalState(true);
  };

  const handleCloseOverlay = () => {
    setOverlayImage(null);
    updateModalState(false);
  };

  const handleAddButtonClick = () => {
    if (userInfo) {
      setShowAddForm(true);
      updateModalState(true);
      ReactGA.event({
        action: "click_add_button",  // これが event_name になります
        category: "User Interaction",
        label: boardTitle || "Home Page"
      });
    }
  };
 
  const handleUrlInputChange = (e) => {
    setUrlInput(e.target.value);
  };

  const handleAddFormSubmit = async (e) => {
    e.preventDefault();
    if (!userInfo) return;
  
    const newUrl = urlInput.trim();
    if (newUrl.length === 0) {
      showInfoBar(t('enterUrl'));
      return;
    }
    if (newUrl.length > 200) {
      showInfoBar(t('urlTooLong'));
      return;
    }
  
    const boardId = urlParams.tbl;
    setIsSubmitting(true);
  
    try {
      const responseData = await submitNewUrl(newUrl, boardId);
      if (responseData.result === 'already-exist') {
        showInfoBar(t('alreadyRegistered'));
        handleNewItems(responseData, setItems);
      } else if (responseData.result === 'add-board') {
        showInfoBar(t('addedToTable'));
        handleNewItems(responseData, setItems);        
      } else if (responseData.result === 'deny-url') {
        showInfoBar(t('invalidUrl'));
        handleNewItems(responseData, setItems);
      } else if (responseData.result === 'ready-generate') {
        showInfoBar(t('processingRequest'));
        handleNewItems(responseData, setItems);        
      } else {
        console.error(t('error'), responseData.message || t('unknownError'));
      }
    } catch (error) {
      console.error(t('urlSubmissionError'), error);
      showInfoBar(t('urlSubmissionFailed'));
    } finally {
      setIsSubmitting(false);
      setShowAddForm(false);
      setUrlInput('');
      updateModalState(false);
    }
  };

  const closeAddForm = () => {
    setShowAddForm(false);
    setUrlInput('');
    updateModalState(false);
  };

  const handleSignInSuccess = useCallback(() => {
    setShowLogin(false);
    showInfoBar(t('loginSuccess'));
    updateModalState(false);
    setIsClipDataFetched(false);
  
    // 現在のURLパラメータを取得
    const currentParams = new URLSearchParams(window.location.search);
    
    // ページをリロード（現在のURLパラメータを保持）
    window.location.href = `${window.location.pathname}?${currentParams.toString()}`;
  }, [showInfoBar, updateModalState, t]);

  useEffect(() => {
    if (userInfo) {
      const savedParams = localStorage.getItem('loginRedirectParams');
      if (savedParams) {
        localStorage.removeItem('loginRedirectParams');
        window.location.href = `${window.location.pathname}?${savedParams}`;
      }
    }
  }, [userInfo]);

  const handleSignOut = useCallback(async () => {
    try {
      await signOut();
      window.location.reload();
    } catch (error) {
      console.error(t('logoutError'), error);
      showInfoBar(t('logoutFailed'));
    }
  }, [signOut, showInfoBar, t]);

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen(prevState => !prevState);
    updateModalState(!isDrawerOpen);
    if (isDrawerOpen) {
      // ドロワーを閉じるときにフラグをリセット
      setShowFollowListOnNextOpen(false);
    }
  }, [isDrawerOpen, updateModalState]);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    updateModalState(false);
  }, [updateModalState]);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
    updateModalState(true);
  }, [updateModalState]);

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (!isDrawerOpen && !isFullScreenCardOpen && !isSearchModalOpen) {
        openDrawer();
      }
    },
    onSwipedLeft: (eventData) => {
      if (isDrawerOpen && !isFullScreenCardOpen && !isSearchModalOpen) {
        closeDrawer();
      }
      // SearchButtonのモーダルが開いているときの左スワイプ処理を削除
    },
    preventDefaultTouchmoveEvent: !isSearchModalOpen, // モーダルが開いているときはデフォルトのタッチムーブイベントを許可
    trackMouse: true
  });

  // const handleHomeClick = useCallback(() => {
  //   const isCurrentlyHome = !urlParams.tbl && !urlParams.tag && !urlParams.loc && !urlParams.style && !urlParams.area && !urlParams.ch;
    
  //   if (!isCurrentlyHome) {
  //     clearBoardInfo();
  //     setUrlParams({ ch: 'discover' });  // ここを変更
  //     setPage(1);
  //     setItems([]);
  //     setPastItems([]);
  //     setHasMore(true);
  //     setShouldFetch(true);
  //     navigate(`${location.pathname}?ch=discover`);  // ここを変更
  //   }
    
  //   closeDrawer();
  // }, [navigate, location.pathname, clearBoardInfo, closeDrawer, urlParams, setUrlParams]);

  const handleRecommendedClick = useCallback(() => {
    handleNavigation(`${location.pathname}?ch=recommend`);
  }, [handleNavigation, location.pathname]);

  const handleAddToBoard = useCallback(async (boardId, itemId) => {
    try {
      await addItemToBoard(boardId, itemId);
      // ボードの情報を非同期で更新
      loadBoards();
      return { success: true };
    } catch (error) {
      console.error(t('addToBoardError'), error);
      return { success: false, error };
    }
  }, [loadBoards, t]);

  const handleRemoveFromBoard = useCallback((removedItemId) => {
    setItems(prevItems => prevItems.filter(item => item.id !== removedItemId));
    setPastItems(prevPastItems => prevPastItems.filter(item => item.id !== removedItemId));
    showInfoBar(t('clipRemovedUpdating'));
    // setShouldFetch(true);
  }, [showInfoBar, t]);

  const handleUserItemClick = useCallback(() => {
    setIsUserDataModalOpen(true);
    updateModalState(true);
  }, [updateModalState]);

  const handleUserDataUpdate = useCallback(async (newUserData) => {
    try {
      await updateUserData(newUserData);
      await refetchUserInfo();
      showInfoBar(t('usernameUpdated'));
      setIsUserDataModalOpen(false);
      updateModalState(false);
    } catch (error) {
      console.error(t('usernameUpdateError'), error);
      showInfoBar(t('usernameUpdateFailed'));
    }
  }, [refetchUserInfo, showInfoBar, updateModalState, t]);

  const handleAgeConfirmation = useCallback((confirmed) => {
    if (confirmed) {
      setIsSensitive(true);
      if (pendingNavigation) {
        pendingNavigation();
      }
    } else {
      showInfoBar(t('accessDenied'));
    }
    setShowAgeConfirmation(false);
    setPendingNavigation(null);
  }, [pendingNavigation, showInfoBar, t]);

  

  const fetchAndCacheTags = useCallback(async () => {
    if (!isTagsFetched && cachedTags.length === 0) {
      setIsTagsLoading(true);
      try {
        const response = await fetchTags();
        if (response && response.tags_data && response.sensitive_tags_data) {
          setCachedTags(response.tags_data.tags);
          setTagsValidCombinations(response.tags_data.valid_combinations); // 追加
          setCachedSensitiveTags(response.sensitive_tags_data.tags);
          setSensitiveTagsValidCombinations(response.sensitive_tags_data.valid_combinations); // 追加
          setIsTagsFetched(true);
        }
      } catch (error) {
        console.error('Failed to fetch tags:', error);
        showInfoBar(t('failedToFetchTags'));
      } finally {
        setIsTagsLoading(false);
      }
    }
  }, [isTagsFetched, cachedTags, fetchTags, showInfoBar, t]);

  useEffect(() => {
    if (isDiscoverPage) {
      fetchAndCacheTags();
    }
  }, [isDiscoverPage, fetchAndCacheTags]);


  const handleLeaveBoard = useCallback(() => {
    setIsLeaveBoardModalOpen(true);
    updateModalState(true);
  }, [updateModalState]);

  const handleLeaveBoardSuccess = useCallback(() => {
    showInfoBar(t('leftBoardSuccess'));
    clearBoardInfo();
    loadBoards();
    navigate('/');
  }, [showInfoBar, t, clearBoardInfo, loadBoards, navigate]);

  const handleInvite = async (inviteCode) => {
    startTransition(async () => {
      try {
        const result = await processInvite(inviteCode);
        setInviteResult(result);
        setIsInviteResultModalOpen(true);
        
        // invitecodeの処理後にURLを完全にクリーンアップし、ルートURLに戻す
        const rootUrl = `${window.location.protocol}//${window.location.host}`;
        window.history.replaceState({}, '', rootUrl);
      } catch (error) {
        console.error('Invite processing error:', error);
        if (userInfo) {
          setInviteResult({ message: t('inviteProcessingError') });
        } else {
          setInviteResult({ message: t('requiredLogin') });
        }
        setIsInviteResultModalOpen(true);
      }
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const inviteCode = searchParams.get('inv');

    if (inviteCode) {
      startTransition(() => {
        handleInvite(inviteCode);
      });
    }
  }, [location]);

  // URLリンク置換用
  const parseLinks = (text) => {
    if (!text) return null;

    const parts = text.split(/(\{\{.*?\}\})/);
    return parts.map((part, index) => {
      if (part.startsWith('{{') && part.endsWith('}}')) {
        const [url, displayText] = part.slice(2, -2).split('|');
        return (
          <a key={index} href={url} target="_blank" rel="noopener noreferrer">
            {displayText || url}
          </a>
        );
      }
      return part;
    });
  };

  useEffect(() => {
    const fetchAndCacheClipData = async () => {
      if (!isClipDataFetched && userInfo) {  // userInfo チェックを追加
        try {
          const response = await fetchClipData();
          setClipData(response.clip_data);
          setIsClipDataFetched(true);
        } catch (error) {
          console.error('Failed to fetch clip data:', error);
          showInfoBar(t('failedToFetchClipData'));
        }
      }
    };
  
    fetchAndCacheClipData();
  }, [isClipDataFetched, showInfoBar, t, userInfo]);  // userInfo を依存配列に追加


  useEffect(() => {
    if (isRecommendPage) {
      fetchAndCacheTags();
    }
  }, [isRecommendPage, fetchAndCacheTags]);

  const handleEventListModeChange = () => {
    setEventListMode(prevMode => {
      if (isRecommendPage) {
        switch(prevMode) {
          case 'pickup':
            return 'upcoming';
          case 'upcoming':
            return 'recent-coming';
          case 'recent-coming':
            return 'end-soon';
          case 'end-soon':
            return 'pickup';
          default:
            return 'pickup';
        }
      } else {
        switch(prevMode) {
          case 'upcoming':
            return 'recent-coming';
          case 'recent-coming':
            return 'end-soon';
          case 'end-soon':
            return pastItems.length > 0 ? 'past' : 'upcoming';
          case 'past':
            return 'upcoming';
          default:
            return 'upcoming';
        }
      }
    });
  };

  useEffect(() => {
    setEventListMode(isRecommendPage ? 'pickup' : 'upcoming');
  }, [isRecommendPage]);

  const getDisplayedItems = useCallback(() => {
    switch(eventListMode) {
      case 'upcoming':
        return items;
      case 'recent-coming':
        const recentComingItems = items.filter(item => item.is_comming_term);
        return recentComingItems.length > 0 ? recentComingItems : null;
      case 'end-soon':
        const endSoonItems = items.filter(item => item.is_end_soon_term);
        return endSoonItems.length > 0 ? endSoonItems : null;
      case 'pickup':
        if (isRecommendPage) {
          const pickupItems = items.filter(item => item.is_pickup);
          return pickupItems.length > 0 ? pickupItems : null;
        }
        return items;
      case 'past':
        return isRecommendPage ? null : pastItems;
      default:
        return items;
    }
  }, [eventListMode, items, pastItems, isRecommendPage]);

  useEffect(() => {
    const displayedItems = getDisplayedItems();
    if (displayedItems && displayedItems.length <= 10 && hasMore) {
      loadMoreItems();
    }
  }, [eventListMode, getDisplayedItems, loadMoreItems, hasMore]);  
  
  // 新しいuseEffectを追加
  useEffect(() => {
    const displayedItems = getDisplayedItems();
    if ((eventListMode === 'recent-coming' || eventListMode === 'end-soon') && displayedItems === null) {
      loadMoreItems();
    }
  }, [eventListMode]);

  // Add this useEffect hook
  useEffect(() => {
    console.log('showNewsCard state changed:', showNewsCard);
  }, [showNewsCard]);


  useEffect(() => {
    if (isFollowStateLoaded) {
      setShowFollowTip(followItems.length === 0);
    }
  }, [followItems, isFollowStateLoaded]);

  const handleCloseFollowTip = () => {
    setShowFollowTip(false);
  };


  const handleTopicClick = (topicItem, index) => {
    console.log('TopicCard clicked:', topicItem);
    setSelectedNewsIndex(index);
    setShowNewsCard(true);
  };

  useEffect(() => {
    if (fetchError) {
      setIsRetryButtonDisabled(true);
      setRetryTimerStart(Date.now());
      const timer = setTimeout(() => {
        setIsRetryButtonDisabled(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [fetchError]);

  return (
    <div className="App" {...handlers}>
      {isPending ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>{t('loading')}</p>
        </div>
      ) : (
        <>
          {backgroundImageUrl && <BackgroundImage imageUrl={backgroundImageUrl} />}
          {(!isLoading && (urlParams.ch!=="recommend") && !(isTopPage && userInfo)) && (
            <ShareButton 
              boardTitle={boardTitle} 
              boardSubtitle={boardSubtitle}
              backgroundImageUrl={backgroundImageUrl}
              avatarUrl={avatarUrl}
            />
          )}
          <ConfirmationModal
            isOpen={showAgeConfirmation}
            onClose={() => handleAgeConfirmation(false)}
            onConfirm={() => handleAgeConfirmation(true)}
            message={t('ageConfirmation')}
          />      
          <Header toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} isSensitive={isSensitive} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            signOut={handleSignOut}
            setShowLogin={setShowLogin}
            userInfo={userInfo}
            isLoading={authLoading}
            onRecommendedClick={handleRecommendedClick}
            boards={boards}
            boardsLoading={boardsLoading}
            onUserItemClick={handleUserItemClick}
            followItems={followItems}
            setFollowItems={updateFollowItems} 
            isFollowListVisible={isFollowListVisible}
            setIsFollowListVisible={setIsFollowListVisible}
            updateModalState={updateModalState}
            showFollowListOnNextOpen={showFollowListOnNextOpen}
            setShowFollowListOnNextOpen={setShowFollowListOnNextOpen}
            handleNavigation={handleNavigation}
          />
          {isDrawerOpen && (
            <div className="drawer-overlay" onClick={closeDrawer}></div>
          )}
          <div className="main-header">
            {!isLoading && (
              <>
                <h1>{boardTitle}</h1>
                <div className="board-info">
                  {boardDescription && (
                    <div className="board-description">
                      {parseLinks(boardDescription)}
                    </div>
                  )}
                  {avatarUrl && (
                    <div className="sub-title-avatar">
                      <img src={avatarUrl} alt={t('userAvatar')} />
                    </div>
                  )}
                  <div className="sub-header">
                    {boardSubtitle && <div className="sub-title">{boardSubtitle}</div>}
                    {boardMemberCount > 0 && <div className="sub-title-member-count">+{boardMemberCount}</div>}
                  </div>
                </div>

                {(isDiscoverPage && !isLoading && i18n.language === 'jp') && (
                  <>
                    
                    <div className="how-to-links">
                      <a 
                        href="https://hello.poppin.link/#HOW-TO-CLIP" 
                        className="how-to-link" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <span className="how-to-link-main">{t('howToCollect')}</span>
                        <span className="how-to-link-sub">{t('letsClip')}</span>
                      </a>
                      <a 
                        href="https://hello.poppin.link/#HOW-TO-SHARE" 
                        className="how-to-link" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <span className="how-to-link-main">{t('howToShare')}</span>
                        <span className="how-to-link-sub">{t('letsShareTable')}</span>
                      </a>
                    </div>
                  </>
                )}

                {(userInfo && followItems.length === 0 && (isTopPage || urlParams.ch==="recommend") && !isLoading && !isPending && !isContentLoading && i18n.language === 'jp') ? (
                  <>
                    
                    <div className="how-to-links">
                      <a 
                        href="https://hello.poppin.link/#HOW-TO-FOLLOW" 
                        className="how-to-link let-follow" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <span className="how-to-link-main">{t('howToFollow')}</span>
                        <span className="how-to-link-sub">{t('letsFollow')}</span>
                      </a>
                    </div>
                  </>
                ):(
                  <span 
                    className={`past-events-link ${eventListMode !== 'upcoming' ? 'active' : ''}`}
                    onClick={handleEventListModeChange}
                  >
                    <ListFilter size={10} />
                    {eventListMode === 'upcoming' ? t('upcomingEvents') : 
                    eventListMode === 'recent-coming' ? t('recentComingEvents') :
                    eventListMode === 'end-soon' ? t('endSoonEvents') :
                    eventListMode === 'past' ? t('pastEvents') :
                    eventListMode === 'pickup' ? t('pickUp') :
                    t('upcomingEvents')}
                  </span>
                )}


                {isBoardOwner && userInfo && ( //本当はisBoardOwnerだけでいいはずなのになぜか未ログインでloc検索すると出てしまうため応急
                  <div className="edit-icon-container" onClick={() => {
                    setIsBoardEditModalOpen(true);
                    updateModalState(true);
                  }}>
                    <Edit size={20} />
                  </div>
                )}
                {isBoardOwner && userInfo && (
                  <div className="invite-icon-container" onClick={() => {
                    setIsInviteModalOpen(true);
                    updateModalState(true);
                  }}>
                    <UserPlus size={20} />
                  </div>
                )}
                {isBoardMember && userInfo && (
                  <div className="leave-icon-container" onClick={handleLeaveBoard}>
                    <span>{t('joinedTable')}</span>
                    {/* <UserX size={20} /> */}
                  </div>
                )}                
                {(urlParams.tag || urlParams.tbl || urlParams.loc) && isFollowStateLoaded && !isBoardOwner && (
                  <>
                    <FollowButton
                      isFollow={userInfo ? isItemFollow(location.search.slice(1)) : false}
                      onFollowAction={() => handleFollowAction({
                        url_param: location.search.slice(1),
                        board_title: boardTitle,
                        sub_title: boardSubtitle
                      })}
                      isLoading={isFollowLoading}
                    />
                    {(showFollowTip && !isFollowLoading) && <FollowTip onClose={handleCloseFollowTip} />}
                  </>
                )}
              </>
            )}
          </div>
          <Toast
            message={toast.message}
            isVisible={toast.visible}
            onClose={hideToast}
          />
          {isPending || isContentLoading ? (
            <div className="loading-content">{t('loading')}</div>
          ) : (
            <>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {['upcoming', 'pickup'].includes(eventListMode) && 
                !((eventListMode === 'upcoming' && isRecommendPage) || 
                  (eventListMode === 'pickup' && !isRecommendPage)) && 
                newsData.news_items.slice(0, 5).map((newsItem, index) => (
                  <TopicCard 
                    key={`topic-${index}`}
                    item={newsItem} 
                    onClick={() => handleTopicClick(newsItem, index)}
                  />
                ))}

                {getDisplayedItems() && getDisplayedItems().map((item) => (
                  <Card 
                    key={item.id} 
                    item={item} 
                    onClick={() => handleClick(item)} 
                    isNew={item.id === newCardId} 
                    clipData={userInfo ? clipData : {}}
                  />
                ))}
              </Masonry>
              {isLoadingMore ? (
                <div className="loading-more">{t('loadingMore')}</div>
              ) : fetchError ? (
                  <div className="fetch-error-container">
                    <div className="fetch-error-message">{fetchError}</div>
                    <button 
                      className={`fetch-retry-button ${isRetryButtonDisabled ? 'disabled' : ''}`}
                      onClick={() => {
                        setFetchError(null);
                        setShouldFetch(true);
                      }}
                      disabled={isRetryButtonDisabled}
                    >
                      {isRetryButtonDisabled 
                        ? t('retryCountdown', { seconds: Math.ceil(5 - (Date.now() - retryTimerStart) / 1000) })
                        : t('retryLoading')}
                    </button>
                  </div>
              ) : (
                (!getDisplayedItems() || getDisplayedItems().length === 0) && (
                  <div className="no-cards-message">
                    {eventListMode === 'past' ? t('noPastClips') : t('noClips')}
                    {eventListMode !== 'past' && (
                      <RecommendedTags
                        tags={cachedTags}
                        handleNavigation={handleNavigation}
                      />
                    )}
                  </div>
                )
              )}
            </>
          )}
          <SearchButton
            tagsData={{ tags: cachedTags, valid_combinations: tagsValidCombinations }} // 変更
            sensitiveTagsData={{ tags: cachedSensitiveTags, valid_combinations: sensitiveTagsValidCombinations }} // 変更
            isTagsLoading={isTagsLoading}
            showInfoBar={showInfoBar}
            clearBoardInfo={clearBoardInfo}
            isSensitive={isSensitive}
            isModalOpen={isSearchModalOpen}
            setIsModalOpen={setIsSearchModalOpen}
          />
          { (userInfo && (isBoardOwner || isBoardMember)) && (
              <div className="floating-add-button" onClick={handleAddButtonClick}>
                ＋
              </div>
            )
          }
          {selectedItem && (
          <FullScreenCard
            item={selectedItem}
            onClose={handleClose}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleCommentSubmit={handleCommentSubmit}
            isCommentsLoading={commentsLoading}
            handleImageClick={handleImageClick}
            isLoggedIn={!!userInfo}
            setShowLogin={setShowLogin}
            showInfoBar={showInfoBar}
            boards={boards}
            boardsLoading={boardsLoading}
            onAddToBoard={handleAddToBoard}
            onCreateBoard={handleCreateBoard}
            isBoardOwner={isBoardOwner}
            isBoardMember={isBoardMember}
            onRemoveFromBoard={handleRemoveFromBoard}
            setShouldFetch={setShouldFetch}
            updateModalState={updateModalState}
            isFullScreenCardOpen={isFullScreenCardOpen}
            userInfo={userInfo}
            clipData={clipData}
            handleNavigation={handleNavigation}
            />
          )}
          {showNewsCard && (
            <FullScreenNewsCard
              newsData={newsData}
              onClose={() => {
                console.log('Closing FullScreenNewsCard, setting showNewsCard to false');
                setShowNewsCard(false);
              }}
              selectedIndex={selectedNewsIndex}
              handleNavigation={handleNavigation}
            />
          )}
          {showAddForm && (
            <div className="modal-overlay" onClick={closeAddForm}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>{t('addClipToTable')}</h2>
                <p className="modal-description">{t('addClipDescription')}</p>
                <form onSubmit={handleAddFormSubmit} className="modal-form">
                  <input 
                    type="url" 
                    name="url" 
                    placeholder={t('pasteUrlPlaceholder')}
                    required 
                    className="modal-form-input"
                    maxLength={200}
                    value={urlInput}
                    onChange={handleUrlInputChange}
                  />
                  <p className="char-count">{t('charCount', { current: urlInput.length, max: 200 })}</p>
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? t('sending') : t('send')}
                  </button>
                </form>
              </div>
            </div>
          )}
          {isSubmitting && (
            <div className="loading-overlay">
              <div className="loading-spinner"></div>
              <p>{t('sending')}</p>
            </div>
          )}
          {overlayImage && (
            <div className="overlay" onClick={handleCloseOverlay}>
              <img src={overlayImage} alt={t('overlayImage')} />
            </div>
          )}
          <ThemeProvider theme={amplifyTheme}>
          {showLogin && (
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '100000' }}>
              <div ref={loginDialogRef} style={{ backgroundColor: 'white', padding: '0px', borderRadius: '5px' }}>
              <Authenticator
                  signUpAttributes={['email']}
                  loginMechanisms={['email']}
                  socialProviders={['google', 'apple']} 
                  formFields={amplifyFormFields}
                  components={amplifyComponents}
                >
                  {({ signOut, user }) => {
                    if (user) {
                      handleSignInSuccess();
                      return null;
                    }
                    return <></>;
                  }}
                </Authenticator>
              </div>
            </div>
          )}
          </ThemeProvider>
          <UserDataUpdateModal
            isOpen={isUserDataModalOpen}
            onClose={() => {
              setIsUserDataModalOpen(false);
              updateModalState(false);
            }}
            onUpdate={handleUserDataUpdate}
            currentUserData={userInfo || {}}
          />
          <BoardEditModal
            isOpen={isBoardEditModalOpen}
            onClose={() => {
              setIsBoardEditModalOpen(false);
              updateModalState(false);
            }}
            onUpdate={handleBoardUpdate}
            onDelete={handleBoardDelete}
            currentBoardData={{ name: boardTitle, description: boardDescription }}
          />
          <InviteModal
            isOpen={isInviteModalOpen}
            onClose={() => {
              setIsInviteModalOpen(false);
              updateModalState(false);
            }}
            boardId={urlParams.tbl}
          />
          <InviteResultModal
            isOpen={isInviteResultModalOpen}
            onClose={() => setIsInviteResultModalOpen(false)}
            result={inviteResult}
          />
          <LeaveBoardModal
            isOpen={isLeaveBoardModalOpen}
            onClose={() => {
              setIsLeaveBoardModalOpen(false);
              updateModalState(false);
            }}
            boardId={urlParams.tbl}
            onLeaveSuccess={handleLeaveBoardSuccess}
          />
        </>
     )}
    </div>
  );
}

export default App;