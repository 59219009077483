import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Card = ({ item, onClick, isNew, clipData }) => {
  const { t } = useTranslation();
  const [imgError, setImgError] = useState(false);
  const mainTerms = item.term ? item.term.filter(term => term.type === 'main') : [];

  const cardClipData = clipData && clipData[item.id] ? clipData[item.id].clipers : null;

  const renderClipAvatars = () => {
    if (!cardClipData || cardClipData.length === 0) return null;

    const visibleAvatars = cardClipData.slice(0, 5);
    const remainingCount = cardClipData.length - 5;

    return (
      <div className="clip-avatars">
        {visibleAvatars.map((cliper, index) => (
          <img key={index} src={cliper.avatar_url} alt={`Avatar of ${cliper.user_name}`} className="clip-avatar" />
        ))}
        {remainingCount > 0 && (
          <div className="clip-avatar remaining-count">+{remainingCount}</div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={`card ${item.isNew ? 'new-card' : ''}`} onClick={onClick}>
        <div className="image-container" style={{ position: 'relative' }}>
          {item.image && !imgError ? (
            <img
              src={item.image}
              alt={item.title}
              onError={() => setImgError(true)}
            />
          ) : (
            <img src='https://poppin.link/images/other/cover-noimage.png' alt="No image available"/>
          )}
          {item.is_new && (
            <img
              src='https://poppin.link/images/icon/icon_new_02.png'
              alt="New"
              className="new-icon"
            />
          )}
        </div>
        <p className="card-title">{item.title}</p>
        <div className="card-content">
          {mainTerms.length > 0 ? (
            mainTerms.map((term, idx) => (
              term.is_display_on_card && (
              <div key={idx} className={`session ${term.is_end ? 'ended-term' : ''}`}>
                <div className="term-date-container">
                  <p className="date">{term.date}</p>
                  {term.is_status === 'is_during' && <span className="ongoing-small">{t('ongoing')}</span>}
                  {term.is_status === 'is_end_soon' && <span className="endsoon-small">{t('endSoon')}</span>}
                  {term.is_status === 'is_end' && <span className="ended-small">{t('ended')}</span>}
                  {term.is_status === 'is_comming' && <span className="comming-small">{t('comingSoon')}</span>}
                </div>
                <p className="time">{term.time}</p>
                <p className="description">{term.location}</p>
              </div>
              )
            ))
          ) : (
            <p className="card-content-nothing"></p>
          )}
          {item.undisplay_count >= 1 &&(
            <p className='is-hidden-term'>{t('isHiddenTerm', { item: item })}</p>
          )}
        </div>
      </div>
      {renderClipAvatars()}
    </>
  );
};

export default Card;